$raleway:'Raleway', sans-serif;
$open_sans:'Open Sans', sans-serif;
$black:#000000;
$red:#e2001a;
$white:#ffffff;
$grey:#718396;
@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

body{
	font-family: $open_sans;
	h1,h2,h3,h4,h5,h6,p,ul,ol,a{
		margin: 0;
		font-weight: 400;
	}
	ul{
		padding: 0;
		list-style: none;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	strong{
		font-weight: 700;
	}
}

div.page{
    overflow: hidden;
    main{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        &.main_bg1{
            background-image: url('./../img/home_bg.jpg');
        }
        &.main_bg2{
            //background-image: url('./../img/shop_main_bg.jpg');
        }
    }
    &.subpage{
        main{
             background-color: #f4f6f9;
        }
    }
}

div.vertical_height{
	@include fill;
	@media only screen and (max-width: 991px) {
		position: relative;
		top: auto;
		left: auto;
		height: auto;
		width: auto;
	}
	> div{
		display: table;
		height: 100%;
		width: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

.more_btn{
	font-size: 18px;
	line-height: 18px;
	font-weight: 700;
	color: $white;
	font-family: $raleway;
	padding: 21px 44px;
	display: inline-block;
    border-radius: 10px;
    text-decoration: none;
    &:hover{
        color:$white;
    }
	@media only screen and (max-width: 1199px) {
		font-size: 15px;
		line-height: 15px;
		padding: 15px 34px;
	}
	&.red{
		background-color: $red;
	}
	&.grey{
		background-color: $grey;
	}
}

div.default_font{
	font-size: 15px;
	line-height: 30px;
	@media only screen and (max-width: 1199px) {
		font-size: 13px;
		line-height: 28px;
	}
	h1{
		font-size: 43px;
		line-height: 77px;
		@media only screen and (max-width: 1199px) {
			font-size: 35px;
			line-height: 69px;
		}
	}
	h2{
		font-size: 36px;
		line-height: 63px;
		@media only screen and (max-width: 1199px) {
			font-size: 30px;
			line-height: 57px;
		}
	}
	h3{
		font-size: 30px;
		line-height: 36px;
		@media only screen and (max-width: 1199px) {
			font-size: 25px;
			line-height: 31px;
		}
	}
	h4{
		font-size: 24px;
		line-height: 30px;
		@media only screen and (max-width: 1199px) {
			font-size: 20px;
			line-height: 26px;
		}
	}
	h5{
		font-size: 20px;
		line-height: 26px;
		@media only screen and (max-width: 1199px) {
			font-size: 18px;
			line-height: 24px;
		}
	}
	h6{
		font-size: 18px;
		line-height: 30px;
		@media only screen and (max-width: 1199px) {
			font-size: 15px;
			line-height: 27px;
		}
	}
	ul{
		list-style: disc;
		padding-left: 18px;
	}
	ol{
		padding-left: 18px;
	}
	h1,h2,h3,h4,h5,h6,p,ul,ol,a{
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

div.rwd_menu{
    padding-top: 20px;
    width: 270px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -270px;
    background-color: #f3f5f9;
    z-index: 99;
    border-left: 1px solid $red;
    transition: 0.3s all ease;
    overflow: scroll;
    > ul{
        padding: 0 15px;
        &.bottom_ul{
            > li{
                &:first-child{
                    ul.nav{
                        display: flex;
                        flex-flow: column;
                        > li{
                            align-items: stretch;
                            &:nth-child(1){
                                order: 1;
                            }
                            &:nth-child(3){
                                order: 2;
                            }
                            &:nth-child(2){
                                order: 3;
                            }
                            &:nth-child(4){
                                order: 4;
                            }
                            &:nth-child(5){
                                order: 5;
                            }
                            &:nth-child(6){
                                order: 6;
                            }
                        }
                    }
                }
            }
        }
        &.top_ul{
            display: flex;
            flex-flow: column;
            > li{
                align-items: stretch;
                &.search{
                    order: 1;
                    form{
                        width: 85%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
                &.newsletter{
                    order: 2;
                }
                &.login_register{
                    order: 3;
                }
            }
        }
        > li{
            &.login_register{
                padding-top: 0;
                padding-bottom: 0;
                > ul{
                    > li{
                        &.login{
                            padding-bottom: 0;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &.search{
                .input_search{
                    width: 100%;
                    height: 40px;
                    border: none;
                    outline: none;
                    padding: 0 15px;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-family: $open_sans;
                }
            }
            margin-bottom: 20px;
            ul{
                li{
                    margin-bottom: 10px;
                }
            }
            a,p{
             font-size: 15px;   
               text-transform: uppercase;
                color: #5d6374;
                font-weight: 600;
            }
        }
    }
    .close_icon{
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        width: 20%;
        height: 0;
        padding-bottom: 20%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('./../img/delete_icon.png');
        position: absolute;
        right: 0;
        top:55px;
    }
    ul.nav{
        margin-top: 10px;
    }
    .basket{
        padding: 0;
    }
}

header.header{
	border-bottom: 1px solid #e4e4e4;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 98;
    background-color: $white;
	margin-top: 40px;
	@media only screen and (max-width: 991px) {
		padding-bottom: 20px;
	}	
	&:after{
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background-position: right;
		background-repeat: no-repeat;
		background-image: url('./../img/header_line.png');
		position: absolute;
		top: 78px;
		right: 0;
		background-size: contain;
		@media only screen and (max-width: 1199px) {
			width: 69%;
		}
		@media only screen and (max-width: 991px) {
			display: none;
		}	
	}
	div{
		&.logo_box{
			position: relative;
            padding-top: 20px;
            @media only screen and (max-width: 1200px) {
                padding-top: 40px;
            }
            img{
                max-width: 100%;
                height: auto;
            }
			@media only screen and (max-width: 991px) {
				padding-top: 0;
				margin-top: 15px;
				img{
					width: 100px;
					height: auto;
				}
				div.rwd_button{
					width: 30px;
					position: absolute;
					top: 20px;
					right: 0;
					> div{
						height: 3px;
						width: 100%;
						background-color: $red;
						margin-bottom: 3px;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&.header_top, &.header_bottom{
            @media only screen and (max-width: 991px) {
                 padding: 0!important;   
            }
			> ul{
				text-align: right;
				> li{
					display: inline-block;
					vertical-align: middle;
					position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 15%;
                        height: 70%;
                        border-right: 1px solid #d4d4d4;
                    }
					&:last-child{
                        &:after{
                          display: none;  
                        }
                    }
                    &.langs{
                        margin-right: 40px;
						margin-left:10px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 20px;
                        }
                        &:after{
                            display: none;
                        }
                    }
					&.newsletter{
                        padding-right: 30px;
                        @media only screen and (max-width: 1500px) {
                            padding-right: 20px;
                        }
						p{
                            cursor: pointer;
                            margin-right: 30px;
							font-size: 13px;
							line-height: 13px;
							color: #6b6b6b;
							padding-left: 35px;
							padding-top: 5px;
							padding-bottom: 5px;
							display: inline-block;
							font-weight: 600;
							font-family: $raleway;
							background-repeat: no-repeat;
							background-position: left center;
							background-image: url('./../img/mail_icon.png');
						}
					}
					&.search{
                        margin-right: 40px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 20px;
                        }
						input.input_search{
                            margin-right:20px;
							padding-left: 33px;
							width: 88px;
							border: none;
							outline: none;
							background-position: left center;
							background-repeat: no-repeat;
							background-image: url('./../img/search_icon.png');
							font-size: 13px;
							line-height: 13px;
							font-family: $raleway;
							font-weight: 600;
							color:#6b6b6b;
							height: 23px;
						}
					}
					&.login_register{
						padding-top:0;
						padding-bottom:0;
						> ul{
							> li{
								display: inline-block;
								vertical-align: top;
								padding-bottom: 0;
								&.login{
                                    padding-right: 0;
									a{
										background-image: url('./../img/login_icon_w.png');
                                        color: $white;
                                        background-color: $red;
                                        border-radius: 5px;
                                        padding: 14px 20px 14px 41px;
                                        background-position: left 21px center;
									}
								}
								&.register{
                                    @media only screen and (max-width: 1500px) {
                                        padding-left: 20px;
                                    }
									a{
                                        background-image: url('./../img/register_icon.png');
                                        padding-right: 0;
                                        @media only screen and (max-width: 991px) {
                                            padding-right: 20px;
                                        }
									}
								}
								a{
                                    padding: 14px 21px;
									font-size: 13px;
									line-height: 13px;
									display: inline-block;
									padding-left: 21px;
									background-repeat: no-repeat;
									background-position: left center;
									color: #6b6b6b;
									font-family: $raleway;
									font-weight: 600;
                                    transition: 0.3s all ease;
                                    @media only screen and (max-width: 1199px) {
                                        font-size: 12px;
                                    }
								}
								
							}
						}
					}
					div{
						&.menu_box{
                            padding-right: 35px;
                            @media only screen and (max-width: 1500px) {
                                padding-right: 20px;
                            }
							nav.menu{
								ul{
									li{
										display: inline-block;
										vertical-align: top;
										margin-right: 44px;
										padding-top: 26px;
										padding-bottom: 26px;
                                        position: relative;
                                        @media only screen and (max-width: 1500px) {
                                            margin-right: 22px;
                                        }
                                        &:hover{
                                            ul{
                                                visibility: visible;
                                                opacity: 1;
                                            }
                                        }
										@media only screen and (max-width: 1199px) {
											margin-right: 22px;
										}
										&:last-child{
											margin-right: 0;
										}
										a{
											font-size: 17px;
											line-height: 17px;
											font-family: $raleway;
											color: #6b6b6b;
											font-weight: 600;
											@media only screen and (max-width: 1500px) {
												font-size: 15px;
												line-height: 15px;
                                            }
                                            
                                            @media only screen and (max-width: 1200px) {
                                                font-size: 13px;
                                                line-height: 13px;
                                            }
											@media only screen and (max-width: 991px) {
												font-size: 13px;
												line-height: 13px;
											}
										}
                                        .on{
                                            a{
                                                color: $red;
                                            }
                                        }
                                        ul{
                                            opacity: 0;
                                            transition: 0.3s all ease;
                                            visibility: hidden;
                                            position: absolute;
                                            top: 100%;
                                            left: -75px;
                                            margin-left: auto;
                                            margin-right: auto;
                                            text-align: center;
                                            z-index: 5;
                                            width: 200px;
                                            background-color: $white;
                                            border-left: 1px solid #d9dbdd;
                                            border-right: 1px solid #d9dbdd;
                                            border-bottom: 1px solid #d9dbdd;
                                            padding: 10px;
                                            z-index: 50;
                                            > li{
                                                width: 100%;
                                                margin-right: 0;
                                                padding: 0;
                                                //white-space: nowrap;
                                                margin-bottom: 10px;
                                                &:last-child{
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
									}
								}
							}
						}
						&.basket{
							padding-left: 35px;
							padding-top:0;
                            padding-bottom:0;
                            @media only screen and (max-width: 1500px) {
                                padding-left: 20px;
                            }
							a{
								font-size: 13px;
								line-height: 13px;
								padding-left: 30px;
								display: inline-block;
								background-repeat: no-repeat;
								background-position: left center;
								background-image: url('./../img/basket_icon.png');
								font-weight: 600;
								color: #6b6b6b;
								font-family: $raleway;
								span{
									font-weight: 600;
									color: $black;
									font-size: 13px;
									line-height: 13px;
								}
							}
						}
					}
				}
			}
		}
		&.header_top{
			padding: 18px 0;
		}
	}
}

section.slider{
	//height: 740px;
	width: 100%;
	position: relative;
	@media only screen and (max-width: 1199px) {
		//height: 540px;
	}
	> ul{
		@include fill;
		> li{
			@include fill;
			div.image{
				@media only screen and (max-width: 991px) {
					width: 290px;
					margin-left: auto;
					margin-right: auto;
				}	
				img{
					max-width: 100%;
					height: auto;
				}
			}
			div.desc{
				div.default_font{
					margin-bottom: 30px;
					font-family: $raleway;
					color: #5d6374;
					@media only screen and (max-width: 991px) {
						padding-top: 20px;
						text-align: center;
						h1{
							font-size: 20px;
							line-height: 30px;
						}
					}
					strong{
						font-weight: 700;
						color: $black;
					}
					h1,h2,h3,h4,h5,h6,p{
						font-weight: 600;
						margin-bottom: 0;
					}
				}
			}
			div.vertical_height{
				z-index: 2;
			}
			div.buttons{
				@media only screen and (max-width: 991px) {
					margin-bottom: 20px;
					text-align: center;
				}
				ul{
					li{
						display: inline-block;
						vertical-align: middle;
						font-size: 23px;
						line-height: 23px;
						color: $black;
						@media only screen and (max-width: 1199px) {
							font-size: 18px;
							line-height: 18px;
						}
						@media only screen and (max-width: 991px) {
							&:nth-child(2){
								display: none;
							}
							a.more_btn{
								margin: 0 5px 10px 5px;
							}
						}
						p{
							margin: 0 27px;
							@media only screen and (max-width: 1199px) {
								margin: 0 15px;
							}
						}
					}
				}
			}
			div.background{
				background-repeat:no-repeat;
				background-position:center;
				background-size: cover;
				@include fill;
			}
			div.cover{
				background-repeat: repeat-y;
				background-position:left top;
				background-size: contain;
				background-image: url('./../img/slider_cover.png');
				@include fill;
			}
		}
	}
	div.slider_nav{
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 30px;
		z-index: 3;
		> ul{
			text-align: center;
			> li{
				display: inline-block;
				vertical-align: middle;
				&.slider_dots{
					ul{
						margin: 0 30px;
						li{
							margin-right: 16px;
							display: inline-block;
							vertical-align: top;
							width: 12px;
							height: 0;
							padding-bottom: 12px;
							border-radius: 50%;
							background-color: #a1aab0;
							cursor: pointer;
							&:last-child{
								margin-right: 0;
							}
							&.on{
								background-color: #5d6374;
							}
						}
					}
				}
				&.arr{
					cursor: pointer;
				}
			}
		}
	}
}

header.section_header{
	padding: 50px 0 40px 0;
    @media only screen and (max-width: 991px) {
         padding: 10px 0 10px 0;   
    }
	&.underline{
		> div.default_font{
			h2{
				position: relative;
				display: inline-block;
				line-height: 36px;
				z-index: 2;
				&:after{
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					border-bottom: 3px solid $red;
					left: 0;
					bottom: 0;
					z-index: -1;
				}
			}
		}
	}
	div.default_font{
		text-align: center;
		font-family: $raleway;
	}
}

header.section_header2{
    padding: 30px 0;
    @media only screen and (max-width: 991px) {
         padding: 30px 0 30px 0;
        text-align: center;
    }
    div.default_font{
        font-family: $raleway;
        font-weight: 700;
        h1,h2,h3,h4,h5,h6,p{
            font-weight: inherit;
        }
        strong{
            color: $red;
        }
    }
}

section.latest_products{
	div.lates_products_carousel, div.other_products, div.last_viewed{
		overflow: hidden;
		> ul{
			position: relative;
			white-space: nowrap;
			> li{
				display: inline-block;
				vertical-align: top;
				width: 23.5%;
				border: 1px solid #c7d2d9;
				border-radius: 20px;
				white-space: normal;
				margin-right: 2%;
				margin-bottom: 5px;
                background-color: $white;
				@media only screen and (max-width: 1199px) {
					width: 32%;
				}
				@media only screen and (max-width: 991px) {
					width: 100%;
				}
				> article{
					padding: 20px;
					-webkit-box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
					-moz-box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
					box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
					border-radius: 20px;
					> div{
						&.image{
							height: 150px;
							width: 100%;
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
							border: 1px solid #e1e6ea;
							margin-bottom: 20px;
							@media only screen and (max-width: 991px) {
								height: 300px;
							}
						}
						&.desc{
							text-align: center;
							font-family: $raleway;
							font-weight: 700;
							font-size: 18px;
							line-height: 30px;
							padding-bottom: 15px;
							margin-bottom: 15px;
							position: relative;
							&:after{
								content: '';
								display: block;
								width: 50px;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								margin-left: auto;
								margin-right: auto;
								border-bottom: 1px solid #e6e6e6;
							}
							p{
								font-weight: inherit;
							}
						}
						&.options{
							color: #5d6374;
							ul{
								li{
									display: inline-block;
									vertical-align: middle;
									width: 50%;
									font-family: $raleway;
									div{
										&.more{
											a{
												padding-left: 44px;
												background-repeat: no-repeat;
												background-position: left center;
												background-image: url('./../img/to_basket_icon.png');
												font-size: 15px;
												display: inline-block;
												color: #5d6374;
												font-weight: 700;
												padding-top: 10px;
												padding-bottom: 10px;
											}
										}
										&.price{
											text-align: right;
											font-size: 24px;
                                            font-weight: 700;
                                            @media only screen and (max-width: 1500px) {
                                                font-size: 20px;
                                            }
											p{
												font-weight: inherit;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
    div.other_products, div.last_viewed{
        margin-bottom: 30px;
        > ul{
            > li{
                width: 31%;
                background-color: $white;
                margin-right: 3.5%;
                @media only screen and (max-width: 991px) {
                     width: 100%;
                    margin-right: 5%;
                }
            }
        }
    }
}

div.carousel_nav{
	padding: 40px 0;
    &.nav_right{
        @media only screen and (max-width: 991px) {
             padding-top: 0;   
        }
        > ul{
            text-align: right;
            @media only screen and (max-width: 991px) {
                 text-align: center;
            }
        }
    }
	> ul{
		text-align: center;
		> li{
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 32px;
			&:last-child{
				margin-right: 0;
			}
		}
	}
}

section.promoted_products{
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
    background-image:none!important;
	> div.container{
		position: relative;
		z-index: 2;
	}
	div.promoted_carousel{
		overflow: hidden;
		> ul{
			position: relative;
			white-space: nowrap;
			> li{
				display: inline-block;
				vertical-align: top;
				width: 32.5%;
				margin-right: 1.3%;
				white-space: normal;
				@media only screen and (max-width: 1199px) {
					width: 49.3%;
				}
				@media only screen and (max-width: 991px) {
					width: 100%;
				}
				&:hover{
					div.image{
						> div.img{
							transform: scale(1.1);
						}
					}
					div.desc{
						height: 100%!important;
					}
				}
				article{
					position: relative;
					height: 280px;
					width: 100%;
					@media only screen and (max-width: 991px) {
						height: 350px;
					}
					> div{
						&.image{
							@include fill;
							overflow: hidden;
							border-radius: 15px;
							> div.img{
								@include fill;
								transition: 0.3s all ease;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}
							> div.desc{
								width: 100%;
								position: absolute;
								left: 0;
								bottom: 0;
								background-repeat: repeat-x;
								background-position: left bottom;
								background-size: contain;
								background-image: url('./../img/promoted_desc_cover.png');
								text-align: center;
								font-size: 20px;
								line-height: 25px;
								color: $white;
								font-weight: 600;
								height: 84px;
								transition: 0.3s all ease;
								p{
									padding: 36px 0 30px;
									font-weight: inherit;
								}
							}
						}
					}
				}
			}
		}
	}
	div.cover{
        display: none;
		background-repeat: repeat-x;
		background-position: left bottom;
		background-image: url(../img/promoted_cover.png);
		background-size: contain;
		@include fill;
	}
}

section.news{
	div.news_carousel{
		overflow: hidden;
		> ul{
			position: relative;
			white-space: nowrap;
			> li{
				display: inline-block;
				vertical-align: top;
				width: 29%;
				white-space: normal;
				margin-right: 6.5%;
				@media only screen and (max-width: 991px) {
					width: 100%;
					margin-right: 5%;
				}
				article{
					text-align: center;
					 div{
						&.image{
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
							width: 100%;
							height: 240px;
							border-radius: 15px;
							margin-bottom: 30px;
							@media only screen and (max-width: 991px) {
								height: 350px;
							}
						}
						&.date{
							margin-bottom: 30px;
							p{
								font-size: 12px;
								padding: 13px;
								line-height: 12px;
								color: #1a171b;
								background-color: #f0f0f3;
								border-radius: 50px;
								display: inline-block;
							}
						}
						&.desc{
							font-size: 13px;
							line-height: 24px;
							color: #6f7581;
							margin-bottom: 30px;
							h3{
								font-size: 30px;
								line-height: 36px;
								color: #1a171b;
								padding-bottom: 15px;
							}
						}
					}
					.more_btn{
						font-size: 13px;
						line-height: 13px;
						padding: 13px 25px;
						font-weight: 600;
						border: 6px;
					}
				}
			}
		}
	}
}

section.short_about, section.about{
	min-height: 614px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
    @media only screen and (max-width: 991px) {
        background-image: none!important;
        background-color: #f3f5f9;
    }
    

	div.desc{
		position: relative;
		padding: 67px 0;
		&:after{
			content: '';
			display: block;
			bottom: 0;
			right: -30%;
			height: 143px;
			width: 156px;
			position: absolute;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-image: url('./../img/stripes1.png');
			@media only screen and (max-width: 991px) {
				display: none;
			}
		}
		header{
			font-family: $raleway;
			color: $red;
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 20px;
			h1,h2,h3,h4,h5,h6{
				color: #1a171b;
			}
			h2{
				font-size: 48px;
				line-height: 58px;
			}
		}
		div.default_font{
			margin-bottom: 38px;
			color: #6f7581;
		}
	}
}

footer.footer{
	position: relative;
	background-color: #e6e9ed;
	background-image: url('./../img/stripes2.png');
	background-repeat: no-repeat;
	background-position: left bottom;
	div{
		&.footer_top{
			padding: 56px 0;
			border-bottom: 1px solid #d2d5da;
			@media only screen and (max-width: 991px) {
				text-align: center;
				padding:0 0 30px 0;
			}
			header{
				margin-bottom: 20px;
				color: $red;
				font-size: 14px;
				line-height: 20px;
					@media only screen and (max-width: 991px) {
						padding-top: 50px;
					}
			}
			nav.footer_menu{
				ul{
					li{
						margin-bottom: 17px;
						&:last-child{
							margin-bottom: 0;
						}
						a{
							font-size: 12px;
							line-height: 18px;
							color: #757575;
							transition: 0.3s all ease;
							&:hover{
								padding-left: 15px;
							}
						}
					}
				}
			}
		}
		&.footer_bottom{
			padding: 40px 0;
			font-size: 13px;
			line-height: 18px;
			color: #6f7581;
			@media only screen and (max-width: 991px) {
				padding: 15px 0;
			}
			div{
				@media only screen and (max-width: 991px) {
					text-align: center;
				}
				&.devs{
					text-align: right;
					@media only screen and (max-width: 991px) {
						text-align: center;
					}
					p{
						a{
							color: #6f7581;
							font-weight: 600;
							text-decoration: none;
						}
					}
				}
			}
        }
        &.social{
            text-align: center;
            margin: 30px 0;
            > ul{
                > li{
                    display: inline-block;
                    margin-right: 15px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
	}
}

section.top{
    background-color: #f4f6f9;
    div.nav_way{
        padding: 5px 0 5px 0;
        border-bottom: 1px solid #d9dbdd;
        > div{
            display: inline-block;
            vertical-align: top;
            color: #6f7581;
            font-size: 13px;
            line-height: 24px;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 10px;
                    a{
                        font-size: 13px;
                        line-height: 24px;
                        text-decoration: none;
                    }
                    &.on{
                        a{
                            color: #1a171b;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

div.split_view{
    padding-top: 55px;
    > .container{
        position: relative;
        padding-bottom: 50px;
        > div.row{
            z-index: 10;
            position: relative;
        }
        div.shape{
            @include fill;
            &:after{
                content: '';
                display: block;
                bottom: 0;
                left: 100%;
                height: 143px;
                width: 156px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url(./../img/stripes1.png)
            }
        }
    }
    aside{
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        div{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
            &.menu_box, &.filters{
                background-color: $white;
                padding: 29px;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-radius: 10px;
                border-bottom: 3px solid #d9dbdd;
                header{
                    color: #1a171b;
                    font-family: $raleway;
                    div.default_font{
                        h4{
                            strong{
                                font-weight: 500;
                            }
                        }
                    }
                }
                nav.side_menu{
                   > ul{
                        > li{
                            padding: 21px 0 20px 0;
                            border-bottom: 1px solid #d9dbdd;
                            &:last-child{
                                border-bottom: none;
                                padding-bottom: 0;
                            }
                            > div{
                                margin-bottom: 0;
                            }
                             a{
                                color: #6f7581;
                                text-decoration: none;
                                font-weight: 700;
                                font-size: 18px;
                            }
                            > ul{
                                
                                padding: 10px 0 0 20px;
                                &.hidden{
                                    display: none;
                                }
                                >  li{
                                    padding-bottom: 13px;
                                    &:last-child{
                                        padding-bottom: 0;
                                    }
                                    a{
                                        font-size: 15px;
                                        color: #6f7581;
                                        text-decoration: none;
                                        &:hover{
                                            padding-left: 10px;
                                        }
                                    }
                                }
                            }
                            a{
                                transition: 0.3s all ease;
                                &:hover{
                                    color: $red;
                                }
                            }
                        }
                    }
                }
                div.price_bar{
                    padding-top: 15px;
                    p{
                        font-size: 18px;
                        color: #6f7581;
                        font-weight: 700;
                    }
                    div.bar{
                        margin: 23px 0 20px 0;
                        width: 100%;
                        height: 7px;
                        background-color: #92979f;
                        position: relative;
                        > div{
                            cursor: pointer;
                            position: absolute;
                            height: 19px;
                            width: 15px;
                            background-color: $white;
                            -webkit-box-shadow: 0px 0px 6px 3px rgba(211,211,211,1);
                            -moz-box-shadow: 0px 0px 6px 3px rgba(211,211,211,1);
                            box-shadow: 0px 0px 6px 3px rgba(211,211,211,1);
                            top: -7px;
                            border-radius: 3px;
                            &:first-child{
                                left: 0;
                            }
                            &:last-child{
                                right: 0;
                            }
                        }
                    }
                    div.filters_inputs{
                        padding-bottom: 17px;
                        margin-bottom: 17px;
                        border-bottom: 1px solid #d9dbdd;
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: middle;
                                &.first, &.third{
                                    width: 45%;
                                    input.filter_input{
                                        width: 100%;
                                        height: 38px;
                                        padding: 0 10px;
                                        border-radius: 10px;
                                        border-top: 1px solid #d9dbdd;
                                        border-left: 1px solid #d9dbdd;
                                        border-right: 1px solid #d9dbdd;
                                        border-bottom: 3px solid #d9dbdd;
                                        background-color: #f4f6f9;
                                        font-size: 15px;
                                        color: #6f7581;
                                        font-family: $open_sans;
                                        outline: none;
                                    }
                                }
                                &.second{
                                    text-align: center;
                                    width: 10%;
                                }
                            }
                        }
                    }
                }
                 input.filter_price_submit{
                    background-color: $red;
                    color: $white;
                    height: 58px;
                    width: 100%;
                    font-family: $raleway;
                     font-weight: 700;
                     font-size: 18px;
                     border-radius: 10px;
                     border: none;
                }
            }
            &.promoted_side{
                font-family: $raleway;
                color: $white;
                font-size: 15px;
                font-weight: 700;
                border-radius: 15px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 100%;
                min-height: 305px;
                p{
                    font-weight: inherit;
                }
                > div{
                    padding: 30px;
                    > div.default_font{
                        margin-bottom: 50px;
                        color: $white;
                        h3{
                            line-height: 42px;
                            font-weight: 600;
                            strong{
                                color: #ffd200;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.about{
    position: relative;
    padding-bottom: 70px;
    &:after{
        content: '';
        display: block;
        bottom: 0;
        right: -25%;
        height: 143px;
        width: 156px;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('./../img/stripes1.png');
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    div.desc{
        padding-top: 0;
        padding-bottom: 10px;
        &:after{
            display: none;
        }
    }
    div.default_font{
        &:last-child{
            margin-bottom: 30px;
        }
        font-size: 14px;
        line-height: 30px;
        color: #6f7581;
        h6{
            line-height: 36px;
        }
        h1,h2,h3,h4,h5,h6{
            color: #1a171b
        }
    }
    div.image{
        width: 100%;
        height: 530px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            height: 0;
            padding-bottom: 100%;
        }
    }
}

section.products{
    .products_list{
           > ul{
                position: relative;
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 31%;
                    border: 1px solid #c7d2d9;
                    border-radius: 20px;
                    white-space: normal;
                    margin-right: 3.5%;
                    margin-bottom: 3.5%;
                    background-color: $white;
                     &:nth-child(3n){
                        margin-right: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    @media only screen and (max-width: 1199px) {
                        width: 32%;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    > article{
                        padding: 20px;
                        -webkit-box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
                        -moz-box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
                        box-shadow: 0px 3px 7px 0px rgba(24,24,24,0.1);
                        border-radius: 20px;
                        > div{
                            &.image{
                                height: 150px;
                                width: 100%;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                border: 1px solid #e1e6ea;
                                margin-bottom: 20px;
                                @media only screen and (max-width: 991px) {
                                    height: 300px;
                                }
                            }
                            &.desc{
                                text-align: center;
                                font-family: $raleway;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 30px;
                                padding-bottom: 15px;
                                margin-bottom: 15px;
                                position: relative;
                                &:after{
                                    content: '';
                                    display: block;
                                    width: 50px;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin-left: auto;
                                    margin-right: auto;
                                    border-bottom: 1px solid #e6e6e6;
                                }
                                p{
                                    font-weight: inherit;
                                }
                            }
                            &.options{
                                color: #5d6374;
                                ul{
                                    li{
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 50%;
                                        font-family: $raleway;
                                        div{
                                            &.more{
                                                a{
                                                    padding-left: 44px;
                                                    background-repeat: no-repeat;
                                                    background-position: left center;
                                                    background-image: url('./../img/to_basket_icon.png');
                                                    font-size: 15px;
                                                    display: inline-block;
                                                    color: #5d6374;
                                                    font-weight: 700;
                                                    padding-top: 10px;
                                                    padding-bottom: 10px;
                                                }
                                            }
                                            &.price{
                                                text-align: right;
                                                font-size: 24px;
                                                font-weight: 700;
                                                div.old_price{
                                                    text-decoration: line-through;
                                                    font-size: 16px;
                                                    font-weight: 400;
                                                    p{
                                                        text-decoration: inherit;
                                                        font-weight: inherit;
                                                    }
                                                }
                                                p{
                                                    font-weight: inherit;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }

}

.sites{
    margin: 30px 0;
    padding: 9px 20px;
    border-top: 1px solid #d9dbdd;
    border-left: 1px solid #d9dbdd;
    border-right: 1px solid #d9dbdd;
    border-bottom: 3px solid #d9dbdd;
    background-color: $white;
    border-radius: 7px;
    ul{
        li{
            display: inline-block;
            vertical-align: middle;
            div.default_font{
                margin-right: 18px;
                font-weight: 600;
                color: #6f7581;
                p{
                    font-weight: inherit;
                }
            }
            .chosen-container{
                width: 64px!important;
                &.chosen-container-active{
                    .chosen-single{
                        background: none;
                        background-color: #f4f6f9;
                         div{
                            width: 30px;
                        }
                        b{
                            background-image: url('./../img/select_arr.png');
                            background-position:  right 9px center;

                        }
                    }
                }
            }
            .chosen-single{
                border-radius: 8px;
                width: 64px;
                height: 35px;
                font-size: 15px;
                padding-top: 4px;
                background: none;
                background-color: #f4f6f9;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-bottom: 3px solid #d9dbdd;
                font-weight: 700;
                color: #6f7581;
                div{
                    width: 30px;
                }
                b{
                    background-image: url('./../img/select_arr.png');
                    background-position:  right 9px center;
                    
                }
                
            }
            .chosen-drop{
                width: 100%;
                background-color: #f4f6f9;
                .active-result{
                    &.highlighted{
                        background: none;
                        background-color: $red;
                    }
                }
            }
        }
        &.sites_right{
            text-align: right;
            @media only screen and (max-width: 991px) {
                 text-align: center;
                    padding-top: 15px;
            }
            li{
                margin-right: 10px;
                position: relative;
                width: 35px;
                height: 0;
                padding-bottom: 31px;
                background-color: #f4f6f9;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-bottom: 3px solid #d9dbdd;
                border-radius: 8px;
                @media only screen and (max-width: 991px) {
                     margin-right: 5px;   
                }
                &:hover{
                    div.default_font{
                        p{
                            text-decoration: underline;
                            color: $red;
                        }
                    }
                }
                div.default_font{
                    text-align: center;
                    margin-right: 0;
                    p{
                        transition: 0.3s all ease;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

section.product_details{
    div{
        &.details_right{
            > div{
                margin-bottom: 22px;
            }
        }
        &.image{
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-radius: 15px;
            background-color: $white;
            -webkit-box-shadow: 0px 0px 12px 3px rgba(24,24,24,0.13);
            -moz-box-shadow: 0px 0px 12px 3px rgba(24,24,24,0.13);
            box-shadow: 0px 0px 12px 3px rgba(24,24,24,0.13);



        }
        &.desc{
            header{
                margin-bottom: 10px;
                h3{
                    line-height: 44px;
                }
                strong{
                    font-weight: 600;
                }
                div.default_font{
                    font-family: $raleway;
                    color: #1a171b;
                }
            }
            > div.default_font{
                color: #6f7581;
                font-size: 14px;
                line-height: 26px;
            }
        }
        &.price{
            font-size: 14px;
            color: #1a171b;
            font-weight: 600;
            p{
                font-weight: inherit;
            }
            strong{
                font-weight: 600;
                font-size: 20px;
            }
            span{
                font-weight: 700;
                color: $red;
                font-size: 30px;
                margin: 0 5px;
            }
        }
        &.counts{
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 20px;
                    font-weight: 600;
                    &:first-child{
                        margin-right: 10px;
                    }
                    p{
                        font-weight: inherit;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        height: 53px;
                        border-top: 1px solid #d9dbdd;
                        border-left: 1px solid #d9dbdd;
                        border-right: 1px solid #d9dbdd;
                        border-bottom: 3px solid #d9dbdd;
                        &.count_holder{
                            width: 92px;
                            margin-right: 10px;
                            position: relative;
                            border-radius: 8px;
                            overflow: hidden;
                            input{
                                @include fill;
                                border: none;
                                padding: 0 20px;
                                font-family: $open_sans;
                                color: #6f7581;
                                font-size: 20px;
                                outline: none;
                            }
                        }
                        &.count_plus{
                            width: 48px;
                            cursor: pointer;
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url('./../img/plus_icon.png');
                            
                        }
                        &.count_minus{
                            width: 48px;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url('./../img/minus_icon.png');
                            cursor: pointer;
                            border-left: none;
                        }
                    }
                }
            }
        }
        &.availability{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    &:first-child{
                        margin-right: 7px;
                    }
                    div.default_font{
                        font-weight: 700;
                        color: #6f7581;
                        font-size: 14px;
                        p{
                            font-weight: inherit;
                        }
                        .available{
                            color: #45a852;
                        }
                        .unavailable{
                            color: $red;
                        }
                    }
                }
            }
        }
        &.add_to_basket{
            input.basket_submit{
                height: 66px;
                width: 240px;
                font-size: 18px;
                font-weight: 700;
                text-align: left;
                padding-left: 70px;
                font-family: $raleway;
                background-position: center left 20px;
                background-repeat: no-repeat;
                background-image: url('./../img/basket_white_icon.png');
                background-color: $red;
                color: $white;
                border: none;
                border-radius: 10px;
            }
        }
    }
}

div.full_desc{
    margin-bottom: 30px;
    ul{
        &.tabs{
            position: relative;
            top: 1px;
            li{
                margin-right: 10px;
                cursor: pointer;
                display: inline-block;
                vertical-align: top;
                padding: 20px 27px;
                font-size: 18px;
                font-family: $raleway;
                color: #1a171b;
                background-color: $white;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &.tabs_desc{
            border: 1px solid #d9dbdd;
            padding: 37px 30px;
            background-color: $white;
            @media only screen and (max-width: 991px) {
                 padding: 15px;   
            }
        }
        &.product_info{
            margin-bottom: 25px;
            li{
                display: inline-block;
                vertical-align: middle;
                margin-right: 50px;
                color: #6f7581;
                font-size: 16px;
                font-weight: 700;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                    margin-right: 0;
                }
                p{
                    font-weight: inherit;
                }
                span{
                    color: #1a171b;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    div.default_font{
        color: #6f7581;
        font-size: 14px;
        padding-bottom: 37px;
        h1,h2,h3,h4,h5,h6,p,a{
            margin-bottom: 20px;
        }
        ul{
            list-style: none;
            padding-left: 0;
            li{
                position: relative;
                &:before{
                    content: '>';
                    padding-right: 3px;
                }
            }
        }
    }
    div.tab_contact{
        padding: 14px 40px;
        background-color: #e4f3fc;
        font-size: 16px;
        color: #6f7581;
        font-weight: 600;
        border-radius: 10px;
        @media only screen and (max-width: 991px) {
             padding: 14px 15px;
            text-align: center;
        }
        p{
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 82px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url('./../img/question_icon.png');
            font-weight: inherit;
            @media only screen and (max-width: 991px) {
                 padding-top: 82px;
                padding-left: 0;
                background-position: center top;
            }
            a{
                color: #2286cd;
                text-decoration: underline;
                font-weight: inherit;
            }
        }
    }
}

.login_register{
    > .container{
        position: relative;
         padding-bottom: 70px;
        > div.row{
            z-index: 10;
            position: relative;
        }
        div.shape{
            @include fill;
            &:after{
                content: '';
                display: block;
                bottom: 0;
                left: 105%;
                height: 143px;
                width: 156px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url(./../img/stripes1.png)
            }
        }
    }
    div.login, div.register{
        div.login_box{
            padding: 29px;
            background-color: $white;
            border-top: 1px solid #d9dbdd;
            border-left: 1px solid #d9dbdd;
            border-right: 1px solid #d9dbdd;
            border-bottom: 3px solid #d9dbdd;
            border-radius: 10px;
            @media only screen and (max-width: 991px) {
                 padding: 15px;   
            }
            div.holder{
                width: 100%;
                margin-bottom: 11px;
                &:last-child{
                    margin-bottom: 0;
                    padding-top: 20px;
                    @media only screen and (max-width: 991px) {
                         text-align: center;
                    }
                }
                input{
                    &.input{
                        border-radius: 10px;
                        width: 100%;
                        height: 63px;
                        padding: 0 29px;
                        background-color: #f4f6f9;
                        color: #393f4a;
                        font-size: $open_sans;
                        font-weight: 600;
                        font-size: 15px;
                        outline: none;
                        border: 1px solid #d9dbdd;
                    }
                    &.submit{
                        width: 160px;
                        height: 60px;
                        border-radius: 10px;
                        background-color: $red;
                        color: $white;
                        font-family: raleway;
                        font-size: 18px;
                        font-weight: 700;
                        border: none;
                        outline: none;
                    }
                }
                &.rules{
                    padding-top: 20px;
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            font-size: 14px;
                            &:first-child{
                                width: 5%;
                            }
                            &:last-child{
                                width: 95%;
                                padding-top: 4px;
                                p{
                                    padding-left: 15px;
                                }
                            }
                            div.box{
                               width: 100%;
                               height: 0;
                               padding-bottom: 94%;
                                border: 1px solid #d9dbdd;
                                background-color: #f4f6f9;
                                border-radius: 8px;
                                position: relative;
                                cursor: pointer;
                                @media only screen and (max-width: 991px) {
                                     border-radius: 2px;
                                    margin-top: 10px;
                                }
                                > div{
                                    @include fill;
                                    background-position: center;
                                    
                                    background-repeat: no-repeat;
                                    &.yes{
                                        &.on{
                                            background-image: url('./../img/check_icon.png');
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #393f4a;
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: #393f4a;
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: #393f4a;
            }
            :-moz-placeholder { /* Firefox 18- */
              color: #393f4a;
            }
        }
    }
    .login{
        padding-right: 32px;
        @media only screen and (max-width: 991px) {
             padding-right: 0;   
        }
    }
    .register{
        padding-left: 32px;
        @media only screen and (max-width: 991px) {
             padding-left: 0;   
        }
    }
}

section.basket{
    padding-top: 42px;
    div.container{
        position: relative;
        padding-bottom: 90px;
        > div.row{
            position: relative;
            z-index: 10;
        }
        div.shape{
            @include fill;
            &:after{
                content: '';
                display: block;
                bottom: 0;
                left: 105%;
                height: 143px;
                width: 156px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url(./../img/stripes1.png)
            }
        }
    }
    div.basket_steps{
        overflow: hidden;
        margin-bottom: 60px;
        background-color: $white;
        border-radius: 8px;
        border-top: 1px solid #d9dbdd;
        border-left: 1px solid #d9dbdd;
        border-right: 1px solid #d9dbdd;
        border-bottom: 3px solid #d9dbdd;
        @media only screen and (max-width: 991px) {
          margin-bottom: 30px;
        }
        div.col-md-3{
            padding-top: 5px;
            padding-bottom: 5px;
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('./../img/shop_cart_steps.png');
            &:last-child{
                background-image: none;
            }
            color: #1a171b;
             &.basket_active{
                 background-color: $red;
                background-image: url('./../img/shop_carts_steps_on.jpg');
                 .step_name{
                     color: $white;
                 }
            }
            &.basket_prev{
                background-image: url('./../img/shop_cart_steps_prev.jpg');
            }
        }
        div.step_box{
            > div{
                display: inline-block;
                vertical-align: middle;
                &.step_number{
                    margin-left: 7px;
                    margin-right: 10px;
                    width: 36px;
                    height: 0;
                    padding-bottom: 36px;
                    background-color: #ff4d4d;
                    color: $white;
                    position: relative;
                    text-align: center;
                    font-size: 24px;
                    border-radius: 8px;
                }
                &.step_name{
                    font-size: 16px;
                    font-weight: 600;
                    p{
                        font-weight: inherit;
                    }
                }
            }
        }
    }
    div.basket_products{
        margin-bottom: 30px;
        border-bottom: 3px solid #d9dbdd;
        > ul{
            > li{
                padding: 27px 0;
                border-top: 1px solid #d9dbdd;
            }
        }
        div{
            &.basket_products_left{
                display: table;
                width: 100%;
                @media only screen and (max-width: 991px) {
                  display: block;
                }
                > div{
                    display: table-cell;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                       display: block;
                    }
                    &:first-child{
                        width: 140px;
                        @media only screen and (max-width: 991px) {
                           width: 100%;
                        }
                    }
                    .product_image{
                        border-radius: 10px;
                        width: 140px;
                        height: 0;
                        padding-bottom: 140px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        -webkit-box-shadow: 0px 0px 12px 0px rgba(24,24,24,0.16);
                        -moz-box-shadow: 0px 0px 12px 0px rgba(24,24,24,0.16);
                        box-shadow: 0px 0px 12px 0px rgba(24,24,24,0.16);
                        @media only screen and (max-width: 991px) {
                           margin: 0 auto;
                        }
                    }
                    &.product_name{
                        padding: 30px;
                        div.default_font{
                            @media only screen and (max-width: 991px) {
                               text-align: center;
                            }
                            color: #6f7581;
                            h1,h2,h3,h4,h5,h6{
                                margin-bottom: 10px;
                            }
                            strong{
                                color: #1a171b;
                            }
                        }
                    }
                }
            }
            &.basket_products_right{
                padding-top: 25px;
                > ul {
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        width: 21.6%;
                        @media only screen and (max-width: 991px) {
                           width: 100%;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &.counts_holder{
                            width: 35%;
                            @media only screen and (max-width: 991px) {
                               width: 100%;
                            }
                        }
                         div{
                            &.price{
                                font-size: 20px;
                                color: #1a171b;
                                @media only screen and (max-width: 991px) {
                                   text-align: center;
                                }
                            }
                            &.counts{
                                ul{
                                    @media only screen and (max-width: 991px) {
                                       text-align: center;
                                    }
                                    li{
                                        display: inline-block;
                                        vertical-align: middle;
                                        font-size: 20px;
                                        font-weight: 600;
                                        &:first-child{
                                            margin-right: 10px;
                                        }
                                        p{
                                            font-weight: inherit;
                                        }
                                        > div{
                                            display: inline-block;
                                            vertical-align: top;
                                            height: 53px;
                                            border-top: 1px solid #d9dbdd;
                                            border-left: 1px solid #d9dbdd;
                                            border-right: 1px solid #d9dbdd;
                                            border-bottom: 3px solid #d9dbdd;
                                            &.count_holder{
                                                width: 92px;
                                                margin-right: 10px;
                                                position: relative;
                                                border-radius: 8px;
                                                overflow: hidden;
                                                input{
                                                    @include fill;
                                                    border: none;
                                                    padding: 0 20px;
                                                    font-family: $open_sans;
                                                    color: #6f7581;
                                                    font-size: 20px;
                                                    outline: none;
                                                }
                                            }
                                            &.count_plus{
                                                width: 48px;
                                                cursor: pointer;
                                                border-top-left-radius: 8px;
                                                border-bottom-left-radius: 8px;
                                                background-repeat: no-repeat;
                                                background-position: center;
                                                background-image: url('./../img/plus_icon.png');

                                            }
                                            &.count_minus{
                                                width: 48px;
                                                border-top-right-radius: 8px;
                                                border-bottom-right-radius: 8px;
                                                background-repeat: no-repeat;
                                                background-position: center;
                                                background-image: url('./../img/minus_icon.png');
                                                cursor: pointer;
                                                border-left: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .delete{
                                margin-left: auto;
                                padding-left: 28px;
                                background-repeat: no-repeat;
                                background-position: left center;
                                font-size: 14px;
                                background-image: url('./../img/delete_icon.png');
                                cursor: pointer;
                                color: #6f7581;
                                font-weight: 700;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .next_step{
        height: 66px;
        width: 180px;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        padding-left: 82px;
        font-family: $raleway;
        background-position: center left 26px;
        background-repeat: no-repeat;
        background-image: url('./../img/next_step_arr.png');
        background-color: $red;
        color: $white;
        border: none;
        border-radius: 10px;
        outline: none;
    }
    .login_register{
        .login{
            padding-right: 0;
            .holder{
                ul{
                    li{
                        @media only screen and (max-width: 991px) {
                             vertical-align: middle!important;
                            div.box{
                                margin-top: 0!important;
                            }
                        }
                    }
                }
            }
        }
        padding-bottom: 30px;
         div.holder{
              p{
                    padding-bottom: 10px;
                   color: #393f4a;
                font-size: 18px;
                }
            &:last-child{
                padding-top: 0!important;
                
            }
            &.rules{
                
                padding-top: 0!important;
            }
        }   
    }
    .chosen-container{
        width: 100%!important;
    }
    .chosen-single{
        border-radius: 8px;
        width: 100%!important;
        height: 63px;
        font-size: 15px;
        padding-top: 4px;
        background: none;
        background-color: #f4f6f9;
        border-top: 1px solid #d9dbdd;
        border-left: 1px solid #d9dbdd;
        border-right: 1px solid #d9dbdd;
        border-bottom: 1px solid #d9dbdd;
        font-weight: 700;
        color: #393f4a;
        padding: 0;
        div{
            width: 45px;
        }
        b{
            background-image: url('./../img/select_arr.png')!important;
            background-position:  right 29px center!important;
            background-repeat: no-repeat;

        }
        span{
            padding: 17px 29px 0 29px;
            font-weight: 600;
        }

    }
    .chosen-drop{
        width: 100%;
        background-color: #f4f6f9;
        .active-result{
            &.highlighted{
                background: none;
                background-color: $red;
            }
        }
    }
    div.delivery_info{
        font-size: 14px;
        line-height: 30px;
        font-weight: 700;
        color: #393f4a;
        p{
            font-weight: inherit;
        }
        span{
            font-weight: 300;
        }
    }
    div.summary{
        margin-bottom: 30px;
        div.basket_products_left{
            display: table;
            width: 100%;
            @media only screen and (max-width: 991px) {
                 display: block;
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    display: block;
                }    
            }
        }
        div.basket_products_right {
            padding-top: 37px;
            @media only screen and (max-width: 991px) {
                 padding-top: 0;   
            }
            > ul {
                > li{
                    width: 50%;
                    text-align: right;
                    @media only screen and (max-width: 991px) {
                         width: 100%;
                        text-align: center;
                    }
                    div.quantity{
                        font-size: 20px;
                    }
                }
            }
        }
        div.main_summary{
            border-radius: 8px;
            border-top: 1px solid #d9dbdd;
            border-left: 1px solid #d9dbdd;
            border-right: 1px solid #d9dbdd;
            border-bottom: 3px solid #d9dbdd;
            padding: 20px;
            background-color: $white;
            @media only screen and (max-width: 991px) {
                 text-align: center;
            }
            div.row{
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #d9dbdd;
                &:first-child{
                    padding-top: 0;
                }
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
            font-size: 20px;
            color: #1a171b;
            strong{
                color: $red;
            }
        }
    }
}

section.contact{
    padding-top: 40px;
    padding-bottom: 60px;
    header{
        margin-bottom: 40px;
        font-family: $raleway;
        @media only screen and (max-width: 991px) {
             text-align: center;
            margin-bottom: 20px;
        }
    }
    div{
        &.contact_data, &.border_box{
            padding-bottom: 40px;
            div.default_font{
                h1,h2,h3,h4,h5,h6,p{
                    margin-bottom: 5px;
                }
            }
        }
        &.phones_and_mails{
            padding-bottom: 40px;
            ul{
                li{
                    background-repeat: no-repeat;
                    background-position: left center;
                    padding-left: 33px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #6f7581;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.phone{
                        background-image: url('./../img/phone_icon.png');
                    }
                    &.mail{
                         background-image: url('./../img/mail_icon.png');
                    }
                }
            }
        }
        &.border_box{
            color: #393f4a;
            margin-top: 40px;
            padding: 30px;
            background-color: #e4f3fc;
            border-top: 1px solid #d9dbdd;
            border-left: 1px solid #d9dbdd;
            border-right: 1px solid #d9dbdd;
            border-bottom: 3px solid #d9dbdd;
            border-radius: 8px;
            @media only screen and (max-width: 991px) {
                 margin-bottom: 30px;   
            }
        }
    }
    div.default_font{
        font-size: 14px;
        line-height: 30px;
       
    }
    form{
        div.default_font{
            padding-top: 15px;
            text-align: right;
            color: #393f4a;
            font-weight: 600;
            font-size: 15px;
            @media only screen and (max-width: 991px) {
                 text-align: center;
                padding-bottom: 10px;
            }
            p{
                font-weight: inherit;
            }
        }
        div.holder{
            margin-bottom: 10px;
            input{
                &.input{
                    width: 100%;
                    height: 63px;
                    padding: 0 20px;
                    border-top: 1px solid #d9dbdd;
                    border-left: 1px solid #d9dbdd;
                    border-right: 1px solid #d9dbdd;
                    border-bottom: 3px solid #d9dbdd;
                    border-radius: 8px;
                    outline: none;
                    font-size: 15px;
                    color: #393f4a;
                    font-family: $open_sans;
                }
                &.submit{
                    margin-top: 20px;
                    width: 240px;
                    height: 60px;
                    border-radius: 10px;
                    background-color: $red;
                    color: $white;
                    font-family: raleway;
                    font-size: 18px;
                    font-weight: 700;
                    border: none;
                    outline: none;
                }
            }
            textarea.textarea{
                width: 100%;
                height: 200px;
                padding: 0 20px;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-bottom: 3px solid #d9dbdd;
                border-radius: 8px;
                outline: none;
                font-size: 15px;
                color: #393f4a;
                font-family: $open_sans;
                resize: none;
            }
        }
    }
    
}

section.map{
    position: relative;
    height: 350px;
    width: 100%;
    @media only screen and (max-width: 991px) {
         height: 200px;   
    }
    > iframe{
        @include fill;
    }
}

.text-right{
    @media only screen and (max-width: 991px) {
       text-align: center;
    }
}

div.shape{
    @media only screen and (max-width: 991px) {
       display: none;
    }
}


.news_page_list {
	> ul{
		position: relative;
		> li{
			padding-bottom:50px;
			vertical-align: top;
			display: inline-block;
			vertical-align: top;
			width: 29%;
			white-space: normal;
			margin-right: 6.5%;
			@media only screen and (max-width: 991px) {
				width: 100%;
				margin-right: 5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			article{
				text-align: center;
				> div{
					&.image{
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						width: 100%;
						height: 240px;
						border-radius: 15px;
						margin-bottom: 30px;
						@media only screen and (max-width: 991px) {
							height: 350px;
						}
					}
					&.date{
						margin-bottom: 30px;
						p{
							font-size: 12px;
							padding: 13px;
							line-height: 12px;
							color: #1a171b;
							background-color: #f0f0f3;
							border-radius: 50px;
							display: inline-block;
						}
					}
					&.desc{
						font-size: 13px;
						line-height: 24px;
						color: #6f7581;
						margin-bottom: 30px;
						h3{
							font-size: 30px;
							line-height: 36px;
							color: #1a171b;
							padding-bottom: 15px;
						}
					}
				}
				a.more_btn{
					font-size: 13px;
					line-height: 13px;
					padding: 13px 25px;
					font-weight: 600;
					border: 6px;
				}
			}
		}
	}
}

.newsletter_popup{
    display: none;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    .vertical_height{
        @include fill;
        > div{
            display: table;
            > div{
                display: table-cell;
            }
        }
    }
    .popup{
        padding:30px;
        background-color: $white;
        border-top: 1px solid #d9dbdd;
        border-left: 1px solid #d9dbdd;
        border-right: 1px solid #d9dbdd;
        border-bottom: 3px solid #d9dbdd;
        border-radius: 10px;
        position: relative;
        @media only screen and (max-width: 991px) {
            padding: 15px;
        }
        .section_header{
            padding-top: 0;
            @media only screen and (max-width: 991px) {
                padding-bottom: 0;
                h3{
                    font-size: 16px;
                }
            }
        }
        .close_popup{
            @media only screen and (max-width: 991px) {
               position: relative;
               top: -24px;
               z-index: 3;
            }
            cursor: pointer;
            display: block;
            vertical-align: top;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(./../img/delete_icon.png);
            margin-left: auto;
            margin-top: 5px;
        }
        input{
            &.input{
                border-radius: 10px;
                width: 100%;
                height: 63px;
                padding: 0 29px;
                background-color: #f4f6f9;
                color: #393f4a;
                font-size: "Open Sans", sans-serif;
                font-weight: 600;
                font-size: 15px;
                outline: none;
                border: 1px solid #d9dbdd;
                margin-bottom: 11px;
                @media only screen and (max-width: 991px) {
                    height: 43px;
                    font-size: 12px;
                    padding: 0 15px;
                }
            }
            &.submit{
                margin-top: 20px;
                width: 160px;
                height: 60px;
                border-radius: 10px;
                background-color: #e2001a;
                color: #ffffff;
                font-family: raleway;
                font-size: 18px;
                font-weight: 700;
                border: none;
                outline: none;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-top: 4px;
                    height: 43px;
                    font-size: 12px;
                }
            }
        }
    }
}

.product_box{
    p.serial_number{
        font-size: 15px;
        position: relative;
        top: -20px;
    }
}

.currency_list {
	white-space: nowrap;
	margin-right:10px;
	> li {
		display:inline-block;
		a {
			display:block;
			width:30px;
			padding:5px 0;
			border:1px solid #d4d4d4;
			border-radius:3px;
			text-align:center;
			color:#6b6b6b;

			&:hover, &.on {
				background-color: #e9e9e9;
			}
		}
	}
}

.main_galery{
    div.desc{
        background-color: $white;
        padding: 29px;
        border-top: 1px solid #d9dbdd;
        border-left: 1px solid #d9dbdd;
        border-right: 1px solid #d9dbdd;
        border-radius: 10px;
        border-bottom: 3px solid #d9dbdd;
        margin-bottom: 30px;
        > div.default_font{
            color: $grey;
            font-weight: 600;
            p{
                font-weight: inherit;
            }
        }
    }
    div.gal_list{
        > ul{
           > li{
               display: inline-block;
               vertical-align: top;
               width: 30%;
               margin-right: 2%;
               margin-bottom: 2%;
               border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-radius: 10px;
                border-bottom: 3px solid #d9dbdd;
                overflow: hidden;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                &:hover{
                    div{
                        &.image{
                            > div{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
               &:nth-child(3n), &:last-child{
                   margin-right: 0;
               }
                div{
                    &.image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        position: relative;
                        overflow: hidden;
                        > div{
                            @include fill;
                            transition: 0.3s all ease;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            background-color: $white;
                        }
                    }
                    &.desc{
                        text-align: center;
                        padding: 15px;
                        font-size: 13px;
                        line-height: 23px;
                        color: $grey;
                        border: none;
                        margin-bottom: 0;
                        h3{
                            color: $black;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

.pay_now{
    padding-top: 15px;
    a{
        font-family: "Raleway", sans-serif;
        font-weight: 600;
        transition: 0.3s all ease;
        font-size: 13px;
        line-height: 13px;
        display: inline-block;
        color: $white;
        background-color: $red;;
        border-radius: 5px;
        padding: 14px 20px 14px 20px;
    }
}
.myform2_shop_cart_order_confirm{
    .input_email{
        &.input{
            padding-top: 30px;
            textarea{
                resize: none;
                outline: none;
                padding: 20px;
                width: 100% !important;
                min-height: 200px;
                background-color: #ffffff;
                border-top: 1px solid #d9dbdd;
                border-left: 1px solid #d9dbdd;
                border-right: 1px solid #d9dbdd;
                border-radius: 10px;
                border-bottom: 3px solid #d9dbdd;
            }
        }
    } 
}

@media only screen and (max-width: 991px) {
    section.products, .split_view{
        div.container{
            > div.row{
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.col-md-3{
                        order: 2;
                        margin-top: 30px;
                    }
                    &.col-md-9{
                        order: 1;
                    }
                }
            }
        }
    }
}